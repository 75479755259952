import React, { FunctionComponent } from 'react';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import {
  ArticleAuthorSummaryRoot,
  ArticleAuthorSummaryImage,
  ArticleAuthorSummaryContent,
  ArticleAuthorSummaryName,
  ArticleAuthorSummaryTitle,
  ArticleAuthorSummaryBio,
  ArticleAuthorSummaryLink,
} from './ArticleAuthorSummary.style';
import { AuthorSummaryFragment } from '../../../generated/graphql-types';

interface ArticleAuthorSummaryProps {
  author: AuthorSummaryFragment;
}

const ArticleAuthorSummary: FunctionComponent<ArticleAuthorSummaryProps> = ({
  author: { name, title, slug, image, shortBio },
}) => (
  <ArticleAuthorSummaryRoot>
    <ArticleAuthorSummaryImage image={getImage(image as any) as any} alt={name ?? 'Author'} />
    <ArticleAuthorSummaryContent>
      <ArticleAuthorSummaryLink to={`/author/${slug}`}>
        <ArticleAuthorSummaryName>{name}</ArticleAuthorSummaryName>
      </ArticleAuthorSummaryLink>
      <ArticleAuthorSummaryTitle>{title}</ArticleAuthorSummaryTitle>
      <ArticleAuthorSummaryBio
        dangerouslySetInnerHTML={{
          __html: shortBio?.childMarkdownRemark?.html as string,
        }}
      />
    </ArticleAuthorSummaryContent>
  </ArticleAuthorSummaryRoot>
);

export default ArticleAuthorSummary;

export const authorSummaryFragment = graphql`
  fragment AuthorSummary on ContentfulPerson {
    name
    title
    slug
    image {
      gatsbyImageData(width: 400, placeholder: BLURRED)
    }
    shortBio {
      childMarkdownRemark {
        html
      }
    }
  }
`;
