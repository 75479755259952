import styled from '@emotion/styled';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { colours, fonts } from '../../style/variables';
import { breakpoint } from '../../style/utils';

const summaryVerticalBreakpoint = breakpoint({ max: 'sm' });

export const ArticleAuthorSummaryImage = styled(GatsbyImage)`
  width: 120px;
  height: 120px;
  margin-right: 24px;
  border-radius: 50%;
  flex: 0 0 auto;

  @media ${summaryVerticalBreakpoint} {
    width: 100px;
    height: 100px;
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

export const ArticleAuthorSummaryContent = styled.div`
  flex: 1 0 0;
`;

export const ArticleAuthorSummaryName = styled.h3`
  margin-top: 0;
  margin-bottom: 12px;
`;

export const ArticleAuthorSummaryTitle = styled.p`
  margin-top: 0;
  margin-bottom: 12px;
  font-family: ${fonts.body};
  font-size: 20px;
  font-style: italic;
`;

export const ArticleAuthorSummaryBio = styled.div`
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 0;
`;

export const ArticleAuthorSummaryLink = styled(Link)`
  color: inherit;
  transition: color 0.2s ease-in-out;

  &:hover,
  &:focus,
  &:active {
    color: ${colours.primary};
  }
`;

export const ArticleAuthorSummaryRoot = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media ${summaryVerticalBreakpoint} {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;
