import styled from '@emotion/styled';
import { GatsbyImage } from 'gatsby-plugin-image';

import { cover, breakpoint } from '../../style/utils';
import { colours, fonts, shadows } from '../../style/variables';
import Divider from '../Divider';
import AuthorIdentity, { AuthorIdentityImage } from '../AuthorIdentity';

const articleBodyWidth = {
  desktop: 720,
  mobile: 600,
};
const headerPanelShadow = '0 6px 16px rgba(0, 0, 0, 0.20)';

export const ArticleHeader = styled.header`
  min-height: 440px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 48px 20px;

  @media ${breakpoint({ min: 'sm', max: 'md' })} {
    min-height: 360px;
  }
  @media ${breakpoint({ max: 'xs' })} {
    min-height: 280px;
  }
`;

export const ArticleHeaderImageWrapper = styled.div`
  ${cover};
  z-index: -1;
`;

export const ArticleHeaderImage = styled(GatsbyImage)`
  height: 100%;
  width: 100%;
`;

export const ArticleHeaderDetails = styled.div`
  min-width: ${articleBodyWidth.desktop}px;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media ${breakpoint({ max: 'md' })} {
    min-width: 0;
    width: 100%;
    max-width: ${articleBodyWidth.desktop}px;
  }
`;

export const ArticleHeaderDetailsPanel = styled.div`
  background-color: ${colours.white};
  padding: 12px 18px;
  border-radius: 12px;
  box-shadow: ${headerPanelShadow};
  margin-bottom: 16px;
`;

export const ArticleTitle = styled.h1`
  margin: 0;

  @media ${breakpoint({ max: 'xs' })} {
    font-size: 40px;
  }
`;

export const ArticleAuthorAndDate = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
`;

export const HeaderAuthorIdentity = styled(AuthorIdentity)`
  padding-right: 24px;

  ${AuthorIdentityImage} {
    width: 36px;
    height: 36px;
  }
`;

export const ArticleDate = styled.span`
  opacity: 0.6;
  font-style: italic;
`;

export const ArticleTags = styled.span`
  margin: -4px;
  display: flex;
  align-items: center;
`;

export const ArticleTag = styled.span`
  display: block;
  margin: 4px;
  background-color: ${colours.white};
  font-weight: bold;
  font-size: 14px;
  border-radius: 32px;
  height: 32px;
  line-height: 32px;
  padding: 0 12px;
  font-style: normal;
  box-shadow: ${headerPanelShadow};
`;

export const ContentContainer = styled.div`
  width: 100%;
  max-width: ${articleBodyWidth.desktop}px;
  padding: 0 20px;
  margin: 100px auto;

  @media ${breakpoint({ max: 'md' })} {
    margin: 80px auto;
  }
  @media ${breakpoint({ max: 'sm' })} {
    max-width: ${articleBodyWidth.mobile}px;
  }
`;

export const ArticleBody = styled.article`
  font-size: 21px;

  @media ${breakpoint({ max: 'xs' })} {
    font-size: 20px;
  }

  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }

  p,
  li,
  a {
    line-height: 1.4;
  }

  li:not(:last-child) {
    margin-bottom: 8px;
  }

  p,
  ul {
    margin-bottom: 32px;
  }

  ul {
    @media ${breakpoint({ max: 'xs' })} {
      padding-left: 32px;
    }
  }

  img {
    width: 100%;
  }

  .instagram-media {
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }

  hr {
    border: none;
    margin: 60px 0;
    text-align: center;

    &:after {
      font-size: 24px;
      content: '• • •';
      letter-spacing: 4px;
      opacity: 0.4;
      color: ${colours.black};
      width: 100%;
    }
  }

  blockquote {
    font-size: 1.3em;
    font-style: italic;
    margin-top: 44px;
    margin-bottom: 44px;

    @media ${breakpoint({ max: 'xs' })} {
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  a {
    transition: color 0.2s ease-in-out;

    &:hover,
    &:focus,
    &:active {
      color: ${colours.primary};
    }
  }

  table {
    width: 100%;
    margin-bottom: 24px;
    border-collapse: collapse;
    border: 1px solid ${colours.grey.lighter};
    border-radius: 8px;

    th, td {
      padding: 8px 16px;
      text-align: left;
    }
    th {
      background: ${colours.grey.lighter};
    }
  }
`;

const linkGutter = 32;

export const SocialLinks = styled.ul`
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -${linkGutter / 2}px -${linkGutter / 2}px;
  margin-top: 36px;
`;

export const SocialLinkItem = styled.li`
  position: relative;
  padding: ${linkGutter / 2}px;

  > a {
    color: ${colours.black};
    transition: transform ease-in-out 0.25s, color ease-in-out 0.25s;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
      transform: scale(1.1);
      color: ${colours.primary};
    }
  }
`;

export const SectionDivider = styled(Divider)`
  margin: 32px 0;
`;

export const ArticleDivider = styled(Divider)`
  margin: 52px 0;
`;

export const CopyPopup = styled.div`
  position: absolute;
  box-shadow: ${shadows.popup};
  border: 1px solid ${colours.grey.lighter};
  border-radius: 8px;
  left: calc(100% + 4px);
  padding: 8px 12px;
  white-space: nowrap;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
`;
