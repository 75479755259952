import { IGatsbyImageData } from 'gatsby-plugin-image';

export function getFirstName(fullName: string) {
  if (!fullName) {
    return fullName;
  }

  const parts = fullName.split(' ');

  if (parts && parts[0]) {
    return parts[0];
  } else {
    return fullName;
  }
}
