import React, { FunctionComponent, Fragment, useState } from 'react';
import uniqueBy from 'lodash/uniqBy';
import differenceBy from 'lodash/differenceBy';
import useMount from 'react-use/lib/useMount';
import useMountedState from 'react-use/lib/useMountedState';
import { getImage, getSrc } from 'gatsby-plugin-image';
import { Facebook, Twitter, Linkedin, Mail, Link } from 'react-feather';
import qs from 'qs';

import { ArticlePageQuery } from '../../../generated/graphql-types';
import Layout from '../Layout';
import Navbar from '../Navbar';
import {
  ArticleHeader,
  ArticleHeaderImage,
  ArticleHeaderDetails,
  ArticleHeaderDetailsPanel,
  ArticleTitle,
  ArticleAuthorAndDate,
  ArticleDate,
  ArticleTags,
  ArticleTag,
  ArticleHeaderImageWrapper,
  ArticleBody,
  ArticleDivider,
  ContentContainer,
  SectionDivider,
  HeaderAuthorIdentity,
  SocialLinks,
  SocialLinkItem,
  CopyPopup,
} from './ArticlePage.style';
import ArticlePreviewList from '../ArticlePreviewList';
import ArticleAuthorSummary from '../ArticleAuthorSummary';
import { getFirstName } from '../../helpers';
import SEOTags from '../SEOTags';

interface ArticlePageProps {
  data: ArticlePageQuery;
}

const COPIED_POPUP_DURATION = 3000;

const ArticlePage: FunctionComponent<ArticlePageProps> = ({ data }) => {
  const siteMetadata = data.site?.siteMetadata;
  const article = data.article;
  const author = data.article?.author;
  const authorArticles = data.articlesByAuthor.nodes;
  const similarArticles = differenceBy(
    uniqueBy(data.similarArticles.nodes, (a) => a.slug),
    authorArticles,
    (a) => a.slug
  );
  const [showCopiedPopup, setShowCopiedPopup] = useState(false);
  const isMounted = useMountedState();

  useMount(() => {
    if (window.instgrm) {
      window.instgrm.Embeds.process();
    }
  });

  const articleUrl = `${siteMetadata?.siteUrl}/post/${article?.slug}`;

  async function copyUrl() {
    const result = await window.navigator.permissions.query({
      name: 'clipboard-write' as any,
    });
    if (result.state === 'granted' || result.state === 'prompt') {
      navigator.clipboard.writeText(articleUrl);
      setShowCopiedPopup(true);

      setTimeout(() => {
        if (isMounted()) {
          setShowCopiedPopup(false);
        }
      }, COPIED_POPUP_DURATION);
    }
  }

  const facebookShareUrl = `https://facebook.com/dialog/share?${qs.stringify({
    app_id: process.env.GATSBY_FB_APP_ID,
    display: 'popup',
    href: articleUrl,
  })}`;

  const twitterShareUrl = `https://twitter.com/intent/tweet?${qs.stringify({
    url: articleUrl,
    text: article?.title,
  })}`;

  const mailtoLink = `mailto:?${qs.stringify({
    subject: article?.title,
    body: `Check this out: ${articleUrl}`,
  })}`;

  const linkedinShareUrl = `https://www.linkedin.com/sharing/share-offsite?${qs.stringify(
    {
      url: articleUrl,
    }
  )}`;

  const seoImage =
    article?.heroImagePreview && getSrc(article.heroImagePreview as any);

  return (
    <Layout>
      <SEOTags
        title={`${article?.title} | ${siteMetadata?.title}`}
        description={article?.description?.description || ''}
        canonical={`${siteMetadata?.siteUrl}/post/${article?.slug}/index.html`}
        openGraph={{
          title: `${article?.title} | ${siteMetadata?.title}`,
          description: article?.description?.description || '',
          type: 'article',
          url: `${siteMetadata?.siteUrl}/post/${article?.slug}`,
          images: seoImage
            ? [
                {
                  url: seoImage,
                  alt: article?.title || '',
                },
              ]
            : [],
          article: {
            author: `${siteMetadata?.siteUrl}/author/${author?.slug}`,
            published_time: article?.isoPublishDate || '',
          },
        }}
      />
      <Navbar overlay={false} showLogo />
      <ArticleHeader>
        <ArticleHeaderImageWrapper>
          <ArticleHeaderImage
            image={getImage(article?.heroImage as any) as any}
            alt={article?.title ?? 'Article'}
          />
        </ArticleHeaderImageWrapper>
        <ArticleHeaderDetails>
          <ArticleHeaderDetailsPanel>
            <ArticleTitle>{article?.title}</ArticleTitle>
          </ArticleHeaderDetailsPanel>
          <ArticleHeaderDetailsPanel>
            <ArticleAuthorAndDate>
              {author && <HeaderAuthorIdentity author={author} />}
              <ArticleDate>{article?.publishDate}</ArticleDate>
            </ArticleAuthorAndDate>
          </ArticleHeaderDetailsPanel>
          <ArticleTags>
            {article?.tags?.map((tag) => (
              <ArticleTag key={tag}>{tag}</ArticleTag>
            ))}
          </ArticleTags>
        </ArticleHeaderDetails>
      </ArticleHeader>
      <ContentContainer>
        <main>
          <ArticleBody
            dangerouslySetInnerHTML={{
              __html: article?.body?.childMarkdownRemark?.html as string,
            }}
          />
          <SocialLinks>
            <SocialLinkItem>
              <a
                href={facebookShareUrl}
                target="_blank"
                title="Share on Facebook"
              >
                <Facebook size={32} />
              </a>
            </SocialLinkItem>
            <SocialLinkItem>
              <a
                href={twitterShareUrl}
                target="_blank"
                title="Share on Twitter"
              >
                <Twitter size={28} />
              </a>
            </SocialLinkItem>
            <SocialLinkItem>
              <a
                href={linkedinShareUrl}
                target="_blank"
                title="Share on LinkedIn"
              >
                <Linkedin size={28} />
              </a>
            </SocialLinkItem>
            <SocialLinkItem>
              <a href={mailtoLink} target="_blank" title="Share via email">
                <Mail size={28} />
              </a>
            </SocialLinkItem>
            <SocialLinkItem>
              {showCopiedPopup && <CopyPopup>Link coped!</CopyPopup>}
              <a onClick={copyUrl} title="Copy link">
                <Link size={28} />
              </a>
            </SocialLinkItem>
          </SocialLinks>
          {article?.author && (
            <Fragment>
              <ArticleDivider />
              <ArticleAuthorSummary author={article.author} />
            </Fragment>
          )}
          {(authorArticles.length > 0 || similarArticles.length > 0) && (
            <ArticleDivider />
          )}
          {authorArticles.length > 0 && (
            <Fragment>
              <h2>
                Other articles by {getFirstName(article?.author?.name || '')}
              </h2>
              <ArticlePreviewList articles={authorArticles} />
            </Fragment>
          )}
          {similarArticles.length > 0 && (
            <Fragment>
              {authorArticles.length > 0 && <SectionDivider />}
              <h2>Similar Articles</h2>
              <ArticlePreviewList articles={similarArticles} />
            </Fragment>
          )}
        </main>
      </ContentContainer>
    </Layout>
  );
};

export default ArticlePage;
